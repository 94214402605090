#contact{
    .error-list {
        color: $error-color;
        padding: 10px;
    }
    .form-input {
        padding: 10px;
        @include input-placeholder;
    }
    .msg{
        padding: .5em;
        margin: 1em 0;

        p {
            margin: 0;
            text-align: center;
        }

        &.success {
            border: 2px solid #29a913;
            background-color: transparentize($success-color, 0.7);
            color: #636363;
        }
        &.error {
            border: 2px solid #ff0000;
            background-color: transparentize($error-color, 0.7);
            color: #636363;
        }
    }
    .submit {
        background: $secondary;
        color: $light-1;
    }
}

