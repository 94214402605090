#articles{
    .main-content{
        background: white;
    }
    .articles-wrapper{
        .post-wrapper{
            display: flex;
            .image-wrapper, .info-wrapper{
                display: inline-flex;
            }
            .image-wrapper{
                height: 7rem;
                width: 7rem;
                border: 1px solid $grey;
                background-color: $light-1;
                margin-right: 1rem;
                background-size:cover;
            }
            .info-wrapper{
                display: flex;
                flex-direction: column;
            }
        }
        .title{
            font-size: 1.75rem;
            color: $primary;
        }
    }
}
#article{
    .post-wrapper{
        .image-wrapper{
            background-size: cover;
            background-position: center;
            margin-bottom: 2rem;
            margin-top: 1rem;
            width: 100%;
            height: 25rem;
            @extend .card-shadow;
        }
    }
    .post-title{
        color: $primary;
    }
}
