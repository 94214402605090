@import url(/fonts/font-awesome-4.4.0/css/font-awesome.min.css);

.nowrap{
	display: inline-block;
}
.card-shadow{
	box-shadow: 0 4px 2px -2px rgba(0,0,0,0.2);
}
//fix this?
.background-cover{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.text-shadow{
	text-shadow: 3px 3px 0px rgba(0,0,0,0.2);
}
.text-shadow-light{
	text-shadow: 2px 2px 0px rgba(0,0,0,0.2);
}
.text-shadow-hard{
	text-shadow: 2px 2px 0px rgba(0,0,0,0.3);
}
@mixin ease($attr: all, $sec: 0.25s){
  transition: $attr $sec ease;
  &:hover{
    transition: $attr $sec ease;
  }
}
@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}
@mixin fluid-box(){
  width: auto;
  overflow: hidden;
}
@mixin fixed-box($width, $side: 'right'){
  float: $side;
  width: $width;
}
@mixin last-child($direction: 'right', $size : 1em) {
  @if($direction == 'right'){
    ul li, &>div{
      margin-right: $size;
      margin-bottom: 0;
      display: inline-block;
      &:last-child{
        margin-right: 0;
        border-right: 0;
        &:after{
          display: none;
        }
      }
    }
  }
  @if($direction == 'bottom'){
    ul li, &>div{
      display: block;
      margin-right: 0;
      margin-bottom: $size;
      &:last-child{
        margin-bottom: 0;
        border-bottom: 0;
      }
      &:after{
        display: none;
        padding: 0;
      }
    }
  }
}

@mixin seabridges($class : 'dark'){
  @if $class == 'dark'{
    color: rgba(0,0,0, .45);
    a{
      color: rgba(0,0,0,.75);
    }
  }
  @if $class == 'light'{
    color: rgba(255, 255, 255, .45);
    a{
      color: rgba(255,255,255,.75);
    }
  }
  .fa-heart{
    font-size: 80%;
    padding: 0 .10em;
  }
  .fa-code{
    padding: 0 .10em;
  }
  a{
    padding-left: .15em;
    &:hover{
      color: $accent;
    }
  }
}

.fa-custom{
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 1em;
	text-align: left;
	display: inline-block;
}

// PSEUDO CONTENT
@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

// CSS TRIANGLE
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false){
    @include pseudo($pos: $position);
    width: 0;
    height: 0;
    @if $round {
        border-radius: 3px;
    }
    @if $direction == down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
        margin-top: 0 - round( $size / 2.5 );
    } @else if $direction == up {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        margin-bottom: 0 - round( $size / 2.5 );
    } @else if $direction == right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        margin-right: -$size;
    } @else if  $direction == left {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        margin-left: -$size;
    }
}

// INPUT PLACEHOLDER
@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

// MEDIA QUERIES
@mixin mq($width, $type: max) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
    @else {
      @if $type == max {
          $width: $width - 1px;
      }
      @media only screen and (#{$type}-width: $width) {
          @content;
      }
    }
}

// Z-INDEX
@function z($name) {
    @if index($z-indexes, $name) {
        @return (length($z-indexes) - index($z-indexes, $name)) + 1;
    } @else {
        @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
        @return null;
    }
}

// TRUNCATE
@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// HIDE
@mixin hidden {
	display: none;
	visibility: hidden;
}

// SHOW
@mixin visible($state: 'block') {
	display: unquote($state);
	visibility: visible;
}

// UNSELECTABLE
@mixin unselectable {
	-webkit-touch-callout: none;
	user-select: none;
}

// FLUID PROPERTY
// http://www.adrenalinmedia.com.au/blog/january-2016/this-changes-everything-css-%E2%80%98fluid-properties%E2%80%99
@mixin fp($property, $min, $max, $start: 320, $end: 1920, $clip: true, $clipAtStart: true, $clipAtEnd: true) {
  $multiplier: ($max - $min) / ($end - $start) * 100;
  $adder: ($min * $end - $max * $start) / ($end - $start);
  $formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
  @if $clip and $clipAtStart {
    @media (max-width: #{$start + 0px}) {
      #{$property}: $min + 0px;
    }
  }
  @if $clip and $clipAtEnd {
    @media (min-width: #{$end + 0px}) {
      #{$property}: $max + 0px;
    }
  }
  #{$property}: $formula;
}
