#episode{
    aside{
        margin-top: 7rem;
        @include mq('tablet'){
            margin-top: 2rem;
        }
    }
    .episode-desc{
        margin-top: 2rem;
    }
    .episode-guest-wrapper{
        margin-top: 4rem;
        background: linear-gradient(180deg,darken(white, 5%) 0,darken(white, 5%) 7em,white 0,white);
        @include mq('phablet'){
            margin-top: 2rem;
        }
        h4{
            font-family: $body;
            text-transform: uppercase;
            color: $primary;
            font-weight: bold;
            margin-top: 1rem;
            padding-left: 1rem;
            display: inline-block;
        }
        .guest-info{
            @include fluid-box();
            padding-left: 2rem;
            padding-top: .5rem;
            @include mq('phablet'){
                padding-left: 0;
            }
            .guest-name{
                font-family: $headline;
                font-size: 2rem;
                margin-bottom: .5rem;
            }
            .guest-title{
                font-size: 1.15rem;
                font-style: italic;
                margin-bottom: 1rem;
            }
            .guest-bio{
                p{
                    font-size: 1rem;
                    margin-bottom: 1rem;
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                }
            }
        }
        .guest-image-wrapper{
            margin-top: -1rem;
            margin-left: 2rem;
            @include fixed-box(10rem, right);
            @include mq('phablet'){
                margin-left: 0;
            }
            .guest-image{
                width: 10rem;
                height: 10rem;
                background-color: darken(white, 20%);
                background-size: cover;
                background-position: center;
                border: 1px solid black;
                @extend .card-shadow;
            }
        }
        @include mq('tablet-small'){
            .guest-image-wrapper{
                float: none !important;
                margin: 0;
                margin-bottom: 1rem;
                margin-left: 2rem;
                margin-top: 1rem;
            }
        }
        @include mq('phablet'){
            guest-image-wrapper{
            }
        }
    }
    .episode-header{
        position: relative;
        margin-bottom: 2rem;
        @include mq('phablet'){
            margin-bottom: 1rem;
        }
    }
    .episode-date{
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        color: white;
        text-transform: uppercase;
        z-index: z('raised');
        font-weight: bold;
        font-size: .8rem;
        display: inline-flex;
        align-items: center;
        .date{
            font-size: 1rem;
            margin-left: 1rem;
        }
    }
    .episode-image-wrapper{
        padding-top: 5rem;
        .episode-image{
            min-height: 20rem;
            position: relative;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            .cover{
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                background: $primary;
                opacity: .35;
            }
        }
    }
    .episode-number-wrapper{
        background: $secondary;
        color: white;
        padding: .5rem 1rem;
        display: inline-block;
        .episode-number{
            font-family: $body;
            text-transform: uppercase;
            font-size: 1rem;
        }
    }
    .episode-title{
        padding: 1rem;
        background: white;
        display: inline-block;
        line-height: 2rem;
        margin-right: 3rem;
        vertical-align: baseline;
        @extend .card-shadow;
        @include mq('tablet-small'){
            font-size: 1.75rem;
        }
        @include mq('phablet'){
            font-size: 1.25rem;
            line-height: 1.5rem;
        }
    }
    .episode-info{
        position: absolute;
        top: 0;
        left: 0;
    }
    .episode-wrapper{
        @include fluid-box();
        .episode-intro {
            background-color: darken(white, 15%);
            padding: 1rem;
        }
    }
    .container{
        @include mq('tablet'){
            .episode-content{
                padding-left: .75rem;
                padding-right: .75rem;
            }
            .episode-header{
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .episode-image-wrapper{
                .episode-image{
                    height: 20rem;
                }
            }
        }
    }
}
