.main-content{
    padding: 2rem 0;
    background: linear-gradient(180deg,darken(white, 5%) 0,darken(white, 5%) 9em,white 0,white);
    h1{
        color: $primary;
        font-family: $body;
        text-transform: uppercase;
        font-weight: 900;
    }
    .container{
        @include mq('tablet-wide'){
        display: flex;
        flex-direction: column;
        aside{
            order: 2;
            display: flex;
            padding: 0 2rem;
            margin: 0;
            margin-top: 2rem;
            flex-direction: column;
        }
        .content-wrapper{
            order: 1;
            display: flex;
            flex-direction: column;

        }
      }
    }
    @include mq('tablet-wide'){
        padding: 1.5rem 0;
    }
}
.speakpipe-wrapper{
    margin-top: 2rem;
}
h3{
    font-family: $body;

}
footer{
    padding: 1rem 1.5rem;
    padding-bottom: 4rem;
}
.player{
    // background: $secondary;
    border: 1px solid $dark-3;
    width: 100%;
    margin-bottom: 1rem;
    audio{
        width: 100%;
    }
}
.subscribe-banner{
    background: $primary;
    padding: 1rem;
    color: white;
    display: flex;
    align-items: center;
    .container{
        display: flex;
        align-items: center;
    }
    ul{
        margin-left: 2rem;
        display: inline-flex;
        li{
            display: inline-flex;
            margin-right: 1rem;
            &:last-of-type{
                margin-right: 0;
            }
        }
    }
    h4{
        margin-bottom: 0;
        font-family: $body;
        display: inline-flex;
        font-size: 1rem;
        text-align: center;
        line-height: 1.15rem;
    }
    @include mq('tablet-small'){
        .container{
            flex-direction: column;
        }
        h4{
            margin-bottom: 1rem;
        }
        ul{
            margin-left: 0;
        }
    }
}
.subscribe-wrapper{
    display: flex;
    margin-bottom: 1.5rem;
    justify-content: flex-end;
    text-transform: uppercase;
    span, ul{
        display: inline-flex;
    }
    li{
        margin-right: 1rem;
        padding-right: 1rem;
        border-right: 1px solid $light-2;
        display: inline-flex;
        &:last-of-type{
            margin-right: 0;
            padding-right: 0;
            border: 0;
        }
    }
    span{
        margin-right: 1rem;
    }
    a{
        text-decoration: underline;
    }
    @include mq('phablet'){
        align-items: center;
        flex-direction: column;
        span{
            margin-bottom: .5rem;
            margin-right: 0;
        }
    }
}
.share-wrapper{
    display: block;
    margin-bottom: 3rem;
    h5{
        font-family: $body;
        font-weight: normal;
        font-style: italic;
        display: block;
        margin-bottom: 1rem;
    }
    ul{
        li{
            a{
                padding: .35rem 1rem;
                color: white;
                font-size: .9rem;
                @include mq('tablet', 'min'){
                    &:hover{
                        color: white !important;
                    }
                }
                i{
                    margin-right: .35rem;
                }
                &.fb{
                    background-color: $facebook;
                }
                &.tw{
                    background-color: $twitter;
                }
                &.gp{
                    background-color: $googleplus;
                }
                &.pi{
                    background-color: $pinterest;
                }
            }
        }
    }
    @include last-child('right', .75rem);
}
aside{
    h4{
        font-family: $body;
        color: $primary;
        border-bottom: 3px solid $secondary;
        text-transform: uppercase;
        display: inline-block;
        font-size: 1rem;
        padding: 0 3rem .25rem .5rem;
        margin-bottom: 2rem;
        flex-grow: 0;
    }
    @include fixed-box(20rem, right);
    &.recent-episodes{
        ul{
            li{
                display: flex;
                margin-bottom: 1.5rem;
                &:last-of-type{
                    margin-bottom: 0;
                }
                a{
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }
        }
        .thumbnail{
            min-height: 5rem;
            max-height: 5rem;
            min-width: 5rem;
            max-width: 5rem;
            display: inline-flex;
            background-color: darken(white, 10%);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            @extend .card-shadow;
            border: 1px solid $dark-3;
        }
        .secondary-btn{
            margin-top: 2rem;
        }
        .info{
            padding: .5rem .5rem;
            padding-left: 1.25rem;
            display: inline-flex;
            flex-direction: column;
            .title{
                font-family: $headline;
                color: black;
                font-size: 1.25rem;
            }
            .episode-number{
                text-transform: uppercase;
                margin-bottom: .5rem;
                color: $primary;
                font-weight: 600;
                // font-style: italic;
                font-size: .75rem;
            }
        }
        @include mq('tablet-wide'){
            width: 100%;
            .secondary-btn{
                width: auto;
            }
        }
    }
    border: 0;
    @include mq('tablet-wide'){
        margin-left: 1rem;
    }
}
.mobile-nav{
    top: 0;
    background: darken(white, 7%);
    @include last-child('bottom', 1.25rem);
    font-size: 1.15rem;
    border-left: 2px solid $primary;
    nav{
        font-weight: bold;
        position: relative;
    }
    .social-icons{
        display: flex;
        padding: .5rem;
        margin-top: 1rem;
        align-items: center;
        flex-direction: column;
        li{
            display: inline-flex;
        }
    }
}

.share-wrapper{
    margin-top: 2rem;
    border-top: 1px solid darken(white, 30%);
    padding-top: 1rem;
}
.mobile-nav-btn{
    color: white;
    margin-left: 2rem;
    @include mq('tablet-small'){
        margin-left: 0;
    }
}

.post-content{
    padding-left: 4rem;
    p{
        font-size: 1.15rem;
        line-height: 1.75rem;
        margin-bottom: 1.75rem;
    }
    @include mq('tablet-wide'){
        margin-right: 1.5rem;
    }
    @include mq('tablet'){
        margin-right: 0;
    }
}
.secondary-btn{
    text-transform: uppercase;
    color: $primary;
    font-size: 1.05rem;
    font-weight: bold;
    display: inline-flex;
    padding-bottom: .25rem;
    border-bottom: 3px solid rgba(0,0,0,0);
    i, svg{
        margin-right: 0;
        text-align: right !important;
        width: 1rem;
    }
    @include mq('tablet', 'min'){
        &:hover{
            border-bottom: 3px solid $primary;
        }
    }
}
.read-more{
    font-weight: bold;
    color: white;
    // margin-left: .5rem;
    font-size: 1.1rem;
    text-transform: uppercase;
    display: inline-block;
    @include mq('tablet', 'min'){
        &:hover{
            color: $secondary !important;
        }
    }
}
.post-footer-wrapper{
    margin-top: 4rem;
    font-style: italic;
    font-size: 1rem;
}
.copy{
    font-size: 1.15rem;
    a{
        text-decoration: underline;
    }
    ul{
        margin-bottom: 2rem;
        margin-left: 1rem;
        li{
            list-style-position: inside;
            list-style-type: disc;
            padding-left: 1.5em;
            text-indent: -1.5em;
            margin-bottom: 1.5rem;
            line-height: 1.5rem;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }
}
sup, sub{
    font-size: .75rem;
    line-height: 1rem;
}
sup{
    vertical-align: top;
}
sub{
    vertical-align: bottom;
}
.btn{
    display: inline-block;
    color: white;
    text-transform: uppercase;
    padding: .75rem 1.15rem;
    background: darken(white, 10%);
    font-weight: bold;
    border-radius: 4px;
    @extend .text-shadow-hard;
    @extend .card-shadow;
    cursor: pointer;
    i{
        margin-left: .25rem;
    }
    &.main{
        background: $secondary;
        border-color: darken($secondary, 10%);
    }
}
.content-wrapper{
    @include fluid-box();
    background: white;
    border: 1px solid darken(white, 15%);
    @extend .card-shadow;
    padding: 2rem;
}
.page-content{
    padding-top: 10rem;
    @include mq('tablet-small'){
        padding-top: 3.5rem;
    }
}
.social-icons{
    display: inline-flex;
    font-size: 1.25rem;
    li{
        margin-right: 1rem;
        display: inline-flex;
        &:last-of-type{
            margin-right: 0;
        }
        a{
            border-radius: 100%;
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: black;
            @include mq('tablet', 'min'){
                &:hover{
                    color: lighten(black, 50%) !important;
                }
            }
        }
    }
}
.inline-link{
    &.facebook{
        color: $facebook;
    }
    &.twitter{
        color: $twitter;
    }
}
header{
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: z('site-header');
    border-bottom: 0;
    background: $primary;
    @extend .card-shadow;
    .main-header{
        height: 10rem;
        display: flex;
        padding: 0 1rem;
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        padding-left: 0;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        nav{
            display: inline-flex;
            color: white;
            font-weight: bold;
            margin-left: 2rem;
            a{
                color: white;
                @include mq('tablet', 'min'){
                    &:hover{
                        color: darken(white, 20%) !important;
                    }
                }
                &.active{
                    color: $secondary;
                    @include mq('tablet', 'min'){
                        &:hover{
                            color: darken($secondary, 15%) !important;
                        }
                    }
                }
            }
        }
        @include mq('tablet-small'){
            nav, .social-icons{
                display: none;
            }
        }
        .social-icons{
            li{
                a{
                    border: 2px solid white;
                }
            }
        }
        .logo-wrapper{
            display: inline-flex;
            margin-right: auto;
            padding: 0 .5rem;
            .logo{
                background-image: url('/img/AmericanChecklist_Logo.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                height: 10rem;
                width: 12rem;
            }
        }
        @include mq('tablet-small'){
            border-bottom: 2px solid $primary;
            height: 3.5rem;
            .logo-wrapper{
                padding: 0 .5rem;
                .logo{
                    height: 3.5rem;
                    width: 14rem;
                    padding-top: .5rem;
                    background-size: contain;
                    background-position: left center;
                }
            }
        }
        @include mq('phablet'){
            .social-icons{
                display: none;
            }
        }
    }
}
.legal-footer{
    line-height: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    .content-line{
        a{
            color: $secondary;
        }
        display: inline-flex;
        margin-left: .75rem;
        margin-right: .75rem;
        &:first-of-type{
            margin-left: 0;
        }
        &:last-of-type{
            margin-right: 0;
        }
        a{
            margin-left: .25rem;
        }
    }
    @include mq('tablet'){
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .content-line{
            margin: 0;
        }
    }
}
main{
    background-color: white;
}
.page-message{
    font-family: $body;
    text-align: center;
    max-width: 500px;
    background-color: white;
    border: 2px solid $primary;
    padding: 1.5rem;
    margin: 0 auto;
    @extend .card-shadow;
    h2{
        color: $primary;
        font-family: $body;
        text-transform: uppercase;
        font-size: 1.35rem;
    }
    p{
        color: $primary;
    }
}
#error{
  section{
      padding: 5rem 0;
    .title{
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: .5rem;
    }
    .container{
      text-align: center;
    }
  }
}
// An easy way to hide elements, just put the hide class on an element.
.hide {
    display: none;
}
